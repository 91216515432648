import React from "react";

export const SettleManually = (props) => {
  const { referenceNumber, processor, paymentStatus, onChange, update, unitItem } = props;
  const { unitName, status } = unitItem || {};
  return (
    <form>
      <div className="container-fluid ">
        <div className="col-12 ">
          <label className="fs-12 fw-400 mb-1">{"Payment Status"}</label>         
           <select
              className="admin-user-inputs my-1 "
              onChange={onChange}
              placeholder=""
              name="paymentStatus"
              value={paymentStatus}
              required
            >
              <option>{"--SELECT STATUS--"}</option>
              <option value="SUCCESSFUL">{"SUCCESSFUL"}</option>
              <option value="FAILED">{"FAILED"}</option>
              <option value="ABANDONED">{"ABANDONED"}</option>
              <option value="REFUNDED">{"REFUNDED"}</option>
            </select>
        </div>
        <div className="col-12   ">
          <label className="fs-12 fw-400 mb-1">{"Processor"}</label>          
          <select
             className="admin-user-inputs my-1 "
            type="text"
            name="processor"
            value={processor}
            onChange={onChange}
            >
              <option>{"--SELECT PROCESSOR--"}</option>
              <option value="UPSL">{"UPSL"}</option>
              <option value="UPSLInstant">{"UPSLInstant"}</option>
              <option value="NIBSS">{"NIBSS"}</option>
              <option value="ISW">{"ISW"}</option>
            </select>
        </div>
        <div className="col-12   ">
          <label className="fs-12 fw-400 mb-1">{"Reference Number"}</label>
          <input
            className="admin-user-inputs"
            type="text"
            name="referenceNumber"
            value={referenceNumber}
            onChange={onChange}
          />
        </div>

        {update && (
          <div className="col-12   ">
            <label className="fs-12 fw-400 mb-1">{"Status"}</label>
            <select
              className="admin-user-inputs my-1 "
              value={status}
              name="status"
              required
              onChange={onChange}
            >
              <option>{"--SELECT STATUS--"}</option>
              <option value="active">{"ACTIVE"}</option>
              <option value="inactive">{"INACTIVE"}</option>
            </select>
          </div>
        )}
      </div>
    </form>
  );
};
